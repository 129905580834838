import React from "react"
import {navigate, Link} from "gatsby"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import Tooltip from "react-bootstrap/Tooltip"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Alert from "react-bootstrap/Alert"
import {Formik, Field} from "formik"
import * as Yup from "yup"

import Layout from "../components/layout"

import wdsPattern from "../assets/old/img/page-title/webdelseguro-pattern.png"
import icoWds from "../assets/old/img/home/ico-wds.png"
import icoWdsB from "../assets/old/img/home/ico-wds-blanco.png"
import loadingSmall from "../assets/old/img/loading-small.gif"
//import promoProvAereo from "../assets/old/img/promociones/provincia-aereolineas.png"
//import promoRus from "../assets/old/img/promociones/promo-rus.png"

import {categorias, companias} from "./resultados.json"

export default class extends React.Component {
  constructor(props) {
    super(props)
    const estanCargando = {}
    this.coberturas = {}
    companias.forEach(compania => {
      estanCargando[compania.codigo] = true
      this.coberturas[compania.codigo] = {}
      categorias.forEach(categoria => {
        this.coberturas[compania.codigo][categoria.codigo] = {}
      })
    })
    this.state = {
      estanCargando,
      comparar: [],
      modalCompararShow: false,
      modalContinuarPorTelefonoShow: false,
      modalAereolineaShow: false,
      modalRusShow: false,
    }
    this.formsData = {
      token: props.location.state ? props.location.state.token : "",
      nombre: props.location.state ? props.location.state.nombre : "",
      marcaDescripcion: props.location.state ? props.location.state.marcaDescripcion : "",
      versionDescripcion: props.location.state ? props.location.state.versionDescripcion : "",
      anio: props.location.state ? props.location.state.anio : "",
      celular: props.location.state ? props.location.state.celular : "",
      email: props.location.state ? props.location.state.email : "",
    }
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.actualizarCotizaciones()
    } else {
      navigate("/")
    }
  }

  onChangeComparar = (accion, companiaCodigo, categoriaCodigo) => {
    let comparar = [...this.state.comparar]
    if (accion === "add") {
      if (comparar.length === 2) {
        return false
      }
      comparar.push({companiaCodigo, categoriaCodigo})
    } else {
      const pos = comparar
        .map(item => `${item.companiaCodigo}-${item.categoriaCodigo}`)
        .indexOf(`${companiaCodigo}-${categoriaCodigo}`)
      comparar.splice(pos, 1)
    }

    this.setState({comparar})
    return true
  }

  handleContratar = ({compania, categoria, cobertura, formsData}) => {
    navigate("/contratado/", {
      state: {compania, categoria, cobertura, formsData},
      replace: true,
    })
    // enviar mail de contratación
    const asunto = `¡CONTRATACIÓN PENDIENTE! ${formsData.nombre}`
    const cuerpo = `
      <p>Has recibido una nueva contratación desde tu sitio web.</p>
      <p>
        <a href="https://centrix.wokan.com.ar/acbytoken/${formsData.token}">
          Ver datos de la oportunidad en Wokan</a>.
      </p>
      <p>
        <b>**IMPORTANTE**</b> Ponte en contacto rápidamente con tu cliente
        ya que está esperando los detalles de la contratación.
      </p>
      <h2>Datos de la contratación</h2>
      <dl>
        <dt>Nombre</dt>
        <dd>${formsData.nombre}</dd>
        <dt>Correo electrónico</dt>
        <dd>${formsData.email}</dd>
        <dt>Celular</dt>
        <dd>${formsData.celular}</dd>
        <dt>Vehiculo</dt>
        <dd>
          ${formsData.marcaDescripcion} -
          ${formsData.versionDescripcion} -
          ${formsData.anio}
        </dd>
        <dt>Cobertura seleccionada</dt>
        <dd>
          ${compania.nombre} -
          ${cobertura.nombre} -
          $${cobertura.cuota}
        </dd>
      </dl>
    `
    const params = {asunto, cuerpo, responder: formsData.email, destino: "contratacion"}
    fetch(`${process.env.WOKAN_WEBPACK_ENPOINT}/mensajes`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_SID}`,
        "Content-Type": "application/json",
      },
    })
  }

  actualizarCotizaciones() {
    const {token} = this.formsData
    fetch(`${process.env.WOKAN_WEBPACK_ENPOINT}/autos/cotizacion/${token}`, {
      headers: {
        "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_SID}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          alert(
            "Ocurrió un error y no se puede procesar su solicitud, intente nuevamente más tarde por favor."
          )
          // navigate("/", replace)
        } else {
          response.json().then(data => {
            if (data === false) {
              alert(
                "Ocurrió un error y no se puede procesar su solicitud, intente nuevamente más tarde por favor."
              )
              // navigate("/", replace)
            } else {
              const {estanCargando} = this.state
              let todasFinalizadas = true
              data.aseguradoras.forEach(aseguradora => {
                // puede pasar que en wokan hayan más compañías pero aca no las queremos
                if (!this.coberturas.hasOwnProperty(aseguradora.aseguradora.id)) {
                  return false
                }
                // si aún no terminó de cotizar, registro y salgo
                if (aseguradora.cotizador.estado !== "finalizado") {
                  todasFinalizadas = false
                  return false
                }
                // se ponen todas las coberturas en un array con el grupo_id como key
                let coberturasPorGrupo = []
                aseguradora.cotizador.coberturas.forEach(cobertura => {
                  // se dejan afuera las coberturas desactivadas o sin grupos
                  if (!cobertura.cobertura.grupo_id || !cobertura.cobertura.activo) {
                    return false
                  }
                  if (!coberturasPorGrupo.hasOwnProperty(cobertura.cobertura.grupo_id)) {
                    coberturasPorGrupo[cobertura.cobertura.grupo_id] = []
                  }
                  coberturasPorGrupo[cobertura.cobertura.grupo_id].push({
                    codigo: cobertura.cobertura.codigo,
                    descripcion: cobertura.cobertura.descripcion,
                    nombre: cobertura.cobertura.nombre,
                    cuota: parseInt(cobertura.premio),
                    sumaAsegurada: cobertura.suma_asegurada,
                    granizo: cobertura.cobertura.granizo,
                    remolque: cobertura.cobertura.remolque,
                    parabrisas: cobertura.cobertura.parabrisas_luneta,
                    laterales: cobertura.cobertura.cristales_laterales,
                  })
                })
                // se filtran las coberturas por cada grupo para dejar solamente la cobertura más barata por grupo
                let coberturasMin = {}
                coberturasPorGrupo.forEach((coberturas, grupo) => {
                  let min = 0
                  coberturas.forEach(cobertura => {
                    if (min === 0 || cobertura.cuota < min) {
                      min = cobertura.cuota
                      coberturasMin[grupo] = cobertura
                    }
                  })
                })
                // se agrega la cobertura más barata por categoria a this.coberturas
                categorias.forEach(categoria => {
                  if (coberturasMin.hasOwnProperty(categoria.codigo)) {
                    this.coberturas[aseguradora.aseguradora.id][categoria.codigo] =
                      coberturasMin[categoria.codigo]
                  }
                })
                estanCargando[aseguradora.aseguradora.id] = false
              })
              // fin bucle compania
              // si ya finalizaron todas las de wokan, por las dudas también pongo todas las locales como terminadas
              if (todasFinalizadas) {
                companias.forEach(compania => {
                  estanCargando[compania.codigo] = false
                })
              } else {
                // repetimos consulta para traer las nuevas cotizaciones
                setTimeout(() => {
                  this.actualizarCotizaciones()
                }, 2000)
              }
              this.setState({estanCargando})
            }
          })
        }
      })
      .catch(err => {
        alert(
          "¿Está conectado a internet? Ocurrió un error y no se puede procesar su solicitud, intente nuevamente más tarde por favor."
        )
        // navigate("/")
      })
  }

  render() {
    const formsData = this.formsData
    return (
      <Layout>
        <div className="card" style={{border: "none"}}>
          {/* Titulo */}
          <div className="page-title d-flex" style={{backgroundImage: `url(${wdsPattern})`}}>
            <div className="container text-left align-self-center">
              <div className="row">
                <div className="px-4 col-md-7">
                  <ul className="list-inline text-primary">
                    <li>Cotización</li>
                    <li>/</li>
                    <li>
                      {formsData.nombre
                        .toLowerCase()
                        .replace(/\b[a-z]/g, letra => letra.toUpperCase())}
                    </li>
                  </ul>
                  <h2 className="page-title-heading">{formsData.marcaDescripcion}</h2>
                  <div className="page-title-subheading">
                    Modelo:{` `}
                    <strong>
                      {formsData.versionDescripcion} - {formsData.anio}
                    </strong>
                  </div>
                </div>
                <div className="px-4 col-md-5 d-none d-lg-block">
                  <h3 className="page-title-heading" style={{fontSize: "1rem"}}>
                    Comparar coberturas
                  </h3>
                  <p>
                    Para comparar simplemente seleccione 2 coberturas en la parte superior izquierda
                    y luego haga clic en el siguiente botón.
                  </p>
                  <button
                    className="btn btn-style-6 btn-primary mr-3 mb-3"
                    disabled={this.state.comparar.length !== 2}
                    onClick={() => {
                      this.setState({modalCompararShow: true})
                    }}
                  >
                    Comparar coberturas
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Resultados */}



          <div className="container">
            {/* Primera fila */}
            <div className="row d-none d-lg-flex">
              {/* Primera columna: logo del web del seguro */}
              <div className="col-sm-12 col-md-2">
                <div className="btn-car">
                  <img src={icoWds} alt="Web del Seguro"/>
                </div>
              </div>
              {/* Resto de columnas: categorias */}
              {categorias.map(categoria => (
                <div key={categoria.codigo} className="col-md-2">
                  <OverlayTrigger
                    trigger="hover"
                    overlay={<Popover title="¿Qué cubre?">{categoria.descripcion}</Popover>}
                  >
                    <div className="btn-car">
                      <div style={{padding: "0 12px"}}>{categoria.nombre}</div>
                    </div>
                  </OverlayTrigger>
                </div>
              ))}
            </div>
            {/* Resto de filas: cotizaciones por compañía */}
            {companias.map(compania => (
              <div key={compania.codigo} className="row my-2">
                {/* Primera columna: logo compañía */}
                <div className="col-sm-12 col-lg-2">
                  <div className="btn-logo">
                    <img
                      src={require(`../assets/old/img/logos-ase/${compania.logo}`)}
                      alt={compania.nombre}
                    />
                  </div>
                </div>
                {/* Resto de columnas: cotizaciones */}
                {categorias.map(categoria => (
                  <div key={categoria.codigo} className="col-sm-12 col-lg-2">
                    <Cobertura
                      compania={compania}
                      categoria={categoria}
                      cargando={this.state.estanCargando[compania.codigo]}
                      cobertura={this.coberturas[compania.codigo][categoria.codigo]}
                      formsData={formsData}
                      handleContratar={this.handleContratar}
                      onChangeComparar={this.onChangeComparar}
                      handleContinuarPorTelefono={() => {
                        this.setState({
                          modalContinuarPorTelefonoShow: true,
                        })
                      }}
                    />
                  </div>
                ))}
              </div>
            ))}
            <div className="text-center my-5">
              <Link className="btn btn-style-6 btn-primary mr-3 mb-3" to="/">
                Realizar una nueva cotización
              </Link>
            </div>
          </div>
        </div>
        <ModalComparar
          show={this.state.modalCompararShow}
          handleClose={() => {
            this.setState({modalCompararShow: false})
          }}
          comparar={this.state.comparar}
          coberturas={this.coberturas}
          formsData={formsData}
          handleContratar={this.handleContratar}
          handleContinuarPorTelefono={() => {
            this.setState({
              modalCompararShow: false,
              modalContinuarPorTelefonoShow: true,
            })
          }}
        />
        <ModalContinuarPorTelefono
          show={this.state.modalContinuarPorTelefonoShow}
          formsData={formsData}
          handleClose={() => {
            this.setState({modalContinuarPorTelefonoShow: false})
          }}
        />
      </Layout>
    )
  }
}

class Cobertura extends React.Component {
  state = {
    show: false,
    comparar: false,
  }

  handleShow = () => {
    if (window.gtag) {
      window.gtag("event", "ver_detalle")
    }
    this.setState({
      show: true,
    })
  }

  handleClose = () => {
    this.setState({
      show: false,
    })
  }

  handleComparar = () => {
    const {compania, categoria, onChangeComparar} = this.props
    if (this.state.comparar) {
      this.setState({comparar: false})
      onChangeComparar("remove", compania.codigo, categoria.codigo)
    } else {
      if (onChangeComparar("add", compania.codigo, categoria.codigo)) {
        this.setState({comparar: true})
      } else {
        alert("Ya tiene las coberturas seleccionadas, destilde una si desea cambiar.")
      }
    }
  }

  render() {
    const {
      cargando,
      compania,
      categoria,
      cobertura,
      formsData,
      handleContratar,
      handleContinuarPorTelefono,
    } = this.props

    const CategoriaPopover = () => (
      <OverlayTrigger
        placement="bottom"
        trigger="hover"
        overlay={<Popover title="¿Qué cubre?">{categoria.descripcion}</Popover>}
      >
        <div className="btn-car-movile text-center">{categoria.nombre}</div>
      </OverlayTrigger>
    )

    if (cargando) {
      return (
        <div className="btn-price">
          <div className="d-block d-lg-none">
            <CategoriaPopover categoria={categoria}/>
          </div>
          <div className="img-loading">
            <img src={loadingSmall} alt="Cargando resultado"/>
          </div>
          <div className="ico-cobertura"/>
        </div>
      )
    }

    if (!cobertura.hasOwnProperty("codigo")) {
      return (
        <div className="btn-price">
          <div className="d-block d-lg-none">
            <CategoriaPopover categoria={categoria}/>
          </div>
          <div className="d-block text-center icoWdsBlanco">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>No se registra cobertura para su auto en esta compañía</Tooltip>}
            >
              <img src={icoWdsB} alt="Web del Seguro"/>
            </OverlayTrigger>
          </div>
        </div>
      )
    }

    return (
      <>
        <div className="btn-price">
          <OverlayTrigger placement="top" overlay={<Tooltip>Comparar</Tooltip>}>
            <div className="custom-control custom-checkbox text-left check-price d-none d-lg-block">
              <input
                className="custom-control-input"
                name="comparar"
                type="checkbox"
                checked={this.state.comparar}
                onChange={this.handleComparar}
              />
              <label className="custom-control-label" onClick={this.handleComparar}/>
            </div>
          </OverlayTrigger>
          <div className="d-block d-lg-none">
            <CategoriaPopover categoria={categoria}/>
          </div>
          <div className="price">
            <div className="text-center">${cobertura.cuota}</div>
          </div>
          <span className="btn-cobertura" onClick={this.handleShow} style={{cursor: "pointer"}}>
            Ver detalles
          </span>
          <div className="ico-cobertura">
            <div className="text-center">
              <CoberturaIconosList cobertura={cobertura}/>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title style={{marginBottom: 0}}>Detalle de Póliza</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{padding: "0"}}>
            <CoberturaModalContent {...this.props} handleContratar={handleContratar}/>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => {
                if (window.gtag) {
                  window.gtag("event", "contratar")
                }
                handleContratar({compania, categoria, cobertura, formsData})
              }}
              className="btn btn-primary btn-sm"
            >
              ¡Contratar!
            </button>
            <button
              className="btn btn-warning btn-sm"
              type="button"
              onClick={() => {
                if (window.gtag) {
                  window.gtag("event", "telefono")
                }
                this.setState({show: false})
                handleContinuarPorTelefono()
              }}
            >
              <i className="fe-icon-phone mr-1"/> Continuar por teléfono
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

const CoberturaIconosList = ({cobertura}) => {
  const CoberturaIcono = ({cubre, clase, texto}) => (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>{cubre ? texto : `No ${texto.toLowerCase()}`}</Tooltip>}
    >
      <div className={`bg-splite-${clase}-${cubre ? "on" : "off"}`}/>
    </OverlayTrigger>
  )

  return (
    <ul className="list-inline">
      <li>
        <CoberturaIcono cubre={cobertura.granizo} clase="granizo" texto="Cubre granizo"/>
      </li>
      <li>
        <CoberturaIcono cubre={cobertura.remolque} clase="remolque" texto="Cubre remolque"/>
      </li>
      <li>
        <CoberturaIcono
          cubre={cobertura.parabrisas}
          clase="cristales"
          texto="Cubre parabrisas / luneta"
        />
      </li>
      <li>
        <CoberturaIcono
          cubre={cobertura.laterales}
          clase="cristales-lateral"
          texto="Cubre cristales laterles"
        />
      </li>
    </ul>
  )
}

const CoberturaModalContent = ({
                                 categoria,
                                 cobertura,
                                 compania,
                                 formsData,
                                 handleContratar,
                                 titleMinHeight,
                               }) => (
  <>
    <div
      className="page-title d-flex"
      style={{
        backgroundImage: `url(${wdsPattern})`,
        backgroundPosition: "80% 50%",
        marginBottom: 0,
        ...(titleMinHeight && {minHeight: titleMinHeight}),
      }}
    >
      <div className="container text-left align-self-center">
        <div className="row">
          <div className="px-4 col-lg-7">
            <div className="text-primary">
              <b>{categoria.nombre}</b>
            </div>
            <h2 className="page-title-heading">
              <img
                src={require(`../assets/old/img/logos-ase/${compania.logo}`)}
                alt={compania.nombre}
              />
            </h2>
            <div className="page-title-subheading font-weight-bold">
              {formsData.versionDescripcion} Año {formsData.anio}
            </div>
            <div className="page-title-subheading">{cobertura.nombre}</div>
          </div>
          <div className="px-4 col-lg-5">
            <h3 className="page-title-heading" style={{fontSize: "1rem"}}>
              CUOTA MENSUAL
            </h3>
            <h3 className="page-title-heading" style={{fontSize: "3rem", color: "#DC3D25"}}>
              ${cobertura.cuota}
            </h3>
            <div className="text-primary" style={{paddingBottom: 10}}>
              Suma Asegurada: ${cobertura.sumaAsegurada}
            </div>
            <button
              onClick={() => {
                if (window.gtag) {
                  window.gtag("event", "contratar")
                }
                handleContratar({compania, categoria, cobertura, formsData})
              }}
              className="btn btn-style-6 btn-primary mr-3 mb-3"
            >
              ¡Contratar!
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="title-ico-cobertura">
      <div className="ico-cobertura">
        <div className="text-center">
          <CoberturaIconosList cobertura={cobertura}/>
        </div>
      </div>
    </div>
    <div style={{padding: "1rem 1.25rem"}}>
      <div dangerouslySetInnerHTML={{__html: cobertura.descripcion}}/>
      <p className="small">
        * Los precios mostrados son aproximados y pueden variar por diferentes motivos.
      </p>
    </div>
  </>
)

const ModalComparar = ({
                         comparar,
                         coberturas,
                         formsData,
                         handleContratar,
                         handleClose,
                         show,
                         handleContinuarPorTelefono,
                       }) => (
  <Modal show={show} onHide={handleClose} size="xl">
    <Modal.Header closeButton>
      <Modal.Title style={{marginBottom: 0}}>Comparar Coberturas</Modal.Title>
    </Modal.Header>
    <Modal.Body style={{padding: "0"}} className="row">
      {comparar.map(({companiaCodigo, categoriaCodigo}) => (
        <div className="col-md-6" key={`${companiaCodigo}-${categoriaCodigo}`}>
          <CoberturaModalContent
            compania={companias.reduce((acum, compania) =>
              compania.codigo === companiaCodigo ? compania : acum
            )}
            categoria={categorias.reduce((acum, categoria) =>
              categoria.codigo === categoriaCodigo ? categoria : acum
            )}
            cobertura={coberturas[companiaCodigo][categoriaCodigo]}
            formsData={formsData}
            handleContratar={handleContratar}
            titleMinHeight={290}
          />
        </div>
      ))}
    </Modal.Body>
    <div className="modal-footer">
      <button className="btn btn-secondary btn-sm" type="button" onClick={handleClose}>
        Cerrar ventana
      </button>
      <button
        className="btn btn-warning btn-sm"
        type="button"
        onClick={() => {
          if (window.gtag) {
            window.gtag("event", "telefono")
          }
          handleContinuarPorTelefono()
        }}
      >
        <i class="fe-icon-phone mr-1"/> Continuar por teléfono
      </button>
    </div>
  </Modal>
)

const ModalContinuarPorTelefono = ({show, handleClose, formsData}) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title style={{marginBottom: 0}}>Continuar por teléfono</Modal.Title>
    </Modal.Header>
    <Formik
      isInitialValid={true}
      initialValues={{
        celular: formsData.celular,
        whatsapp: false,
        horario: "",
        nombre: formsData.nombre,
      }}
      initialStatus={{
        enviado: false,
      }}
      validationSchema={() =>
        Yup.object().shape({
          celular: Yup.string()
            .trim()
            .matches(/^0\d{2,4}\s?15\d{6,8}$/, "Ingrese el 0 y el 15")
            .required("Requerido"),
          nombre: Yup.string().required("Requerido"),
        })
      }
      onSubmit={(values, {setSubmitting, setStatus}) => {
        if (window.gtag) {
          window.gtag("event", "telefono_enviado")
        }
        const asunto = `¡SOLICITUD DE CONTACTO! ${formsData.nombre}`
        const cuerpo = `
          <p>Has recibido una nueva solicitud de contacto desde tu sitio web.</p>
          <p>
            <a href="https://centrix.wokan.com.ar/acbytoken/${formsData.token}">
              Ver datos de la oportunidad en Wokan</a>.
          </p>
          <h2>Datos de la solicitud</h2>
          <dl>
            <dt>Nombre</dt>
            <dd>${values.nombre}</dd>
            <dt>Celular</dt>
            <dd>${values.celular}</dd>
            <dt>Horario de contacto</dt>
            <dd>${values.horario ? values.horario : "En cuanto antes"}</dd>
            <dt>Prefiere por WhatsApp</dt>
            <dd>${values.whatsapp ? "Si" : "No"}</dd>
            <dt>Vehiculo</dt>
            <dd>
              ${formsData.marcaDescripcion} -
              ${formsData.versionDescripcion} -
              ${formsData.anio}
            </dd>
          </dl>
        `
        const params = {asunto, cuerpo, responder: formsData.email}
        fetch(`${process.env.WOKAN_WEBPACK_ENPOINT}/mensajes`, {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_SID}`,
            "Content-Type": "application/json",
          },
        }).then(response => {
          setStatus({enviado: true})
          setSubmitting(false)
        })
      }}
    >
      {({handleSubmit, isSubmitting, isValid, status}) => (
        <>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              {status.enviado ? (
                <Alert variant="success">
                  ¡Confirmado! Nos contactaremos en el horario indicado.
                </Alert>
              ) : (
                <Alert variant="secondary">Confirme sus datos de contacto por favor.</Alert>
              )}
              <Form.Group controlId="celular">
                <Form.Label>Celular</Form.Label>
                <Field name="celular">
                  {({form, field}) => (
                    <>
                      <Form.Control
                        {...field}
                        placeholder="Ej: 011 1577778888"
                        isInvalid={form.touched[field.name] && form.errors[field.name]}
                      />
                      {form.touched[field.name] && form.errors[field.name] && (
                        <Form.Control.Feedback type="invalid">
                          {form.errors[field.name]}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
              <Form.Group controlId="whatsapp">
                <Field name="whatsapp">
                  {({field}) => (
                    <Form.Check {...field} type="checkbox" label="Prefiero contacto por WhatsApp"/>
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <Form.Label>Horario de contacto</Form.Label>
                <Field name="horario">
                  {({form, field}) => (
                    <>
                      <Form.Control
                        {...field}
                        placeholder="Ej: Entre 9 y 17"
                        isInvalid={form.touched[field.name] && form.errors[field.name]}
                      />
                      {form.touched[field.name] && form.errors[field.name] && (
                        <Form.Control.Feedback type="invalid">
                          {form.errors[field.name]}
                        </Form.Control.Feedback>
                      )}
                      <Form.Text className="text-muted">
                        Si deja en blanco será contactado en cuanto antes.
                      </Form.Text>
                    </>
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <Form.Label>Nombre y apellido</Form.Label>
                <Field name="nombre">
                  {({form, field}) => (
                    <>
                      <Form.Control
                        {...field}
                        isInvalid={form.touched[field.name] && form.errors[field.name]}
                      />
                      {form.touched[field.name] && form.errors[field.name] && (
                        <Form.Control.Feedback type="invalid">
                          {form.errors[field.name]}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting || !isValid || status.enviado}
              >
                {isSubmitting ? "Un momento..." : status.enviado ? "Enviado" : "Confirmar"}
              </Button>
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  </Modal>
)
